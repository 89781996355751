.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

:root {
  --primary-rgb: 59, 130, 246;   /* Blue */
  --secondary-rgb: 139, 92, 246;  /* Purple */
  --accent-rgb: 236, 72, 153;    /* Pink */
}

[data-theme='dark'] {
  --primary-rgb: 99, 102, 241;    /* Indigo */
  --secondary-rgb: 167, 139, 250;  /* Purple lighter */
  --accent-rgb: 244, 114, 182;    /* Pink lighter */
}

.animation-delay-2000 {
  animation-delay: 2s;
}

.animation-delay-4000 {
  animation-delay: 4s;
}

@keyframes drift {
  0% { transform: translate(0, 0) rotate(0deg); }
  50% { transform: translate(100px, 100px) rotate(180deg); }
  100% { transform: translate(0, 0) rotate(360deg); }
}

@keyframes drift-reverse {
  0% { transform: translate(0, 0) rotate(360deg); }
  50% { transform: translate(-100px, -100px) rotate(180deg); }
  100% { transform: translate(0, 0) rotate(0deg); }
}

.animate-drift {
  animation: drift 25s infinite linear;
}

.animate-drift-reverse {
  animation: drift-reverse 30s infinite linear;
}

.typing-indicator {
  display: flex;
  gap: 4px;
  padding: 4px;
}

.typing-indicator span {
  width: 4px;
  height: 4px;
  background: currentColor;
  border-radius: 50%;
  opacity: 0.3;
  animation: typing 1s infinite;
}

.typing-indicator span:nth-child(2) {
  animation-delay: 0.2s;
}

.typing-indicator span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes typing {
  0%, 100% {
    transform: translateY(0);
    opacity: 0.3;
  }
  50% {
    transform: translateY(-4px);
    opacity: 0.8;
  }
}

@keyframes blob {
  0% {
    transform: translate(0px, 0px) scale(1);
  }
  33% {
    transform: translate(30px, -30px) scale(1.1);
  }
  66% {
    transform: translate(-20px, 20px) scale(0.9);
  }
  100% {
    transform: translate(0px, 0px) scale(1);
  }
}

.animate-blob {
  animation: blob 7s infinite cubic-bezier(0.4, 0.0, 0.2, 1);
}