@keyframes glow-pulse {
    0%, 100% {
        box-shadow: 0 0 5px var(--p),
        0 0 10px var(--p);
    }
    50% {
        box-shadow: 0 0 15px var(--p),
        0 0 20px var(--p);
    }
}

.card-new-message {
    border: 2px solid var(--p);
    animation: glow-pulse 2s infinite;
}

.notification-dot {
    position: absolute;
    top: -2px;
    right: -2px;
    width: 12px;
    height: 12px;
    background: var(--p);
    border-radius: 50%;
    box-shadow: 0 0 0 2px white;
}

.notification-ripple {
    position: absolute;
    inset: -4px;
    border-radius: 50%;
    background: var(--p);
    animation: ping 1.5s cubic-bezier(0, 0, 0.2, 1) infinite;
}